$error: #ff4242;

// moveMEoutVariables
@import "./variables/moveMEoutVariables.module.scss";
// bestCardProcessingRates
@import "./variables/bestCardProcessingRatesVariables.module.scss";
// vpnpricecomparison
@import "./variables/vpnpricecomparisonVariables.module.scss";

// workersCompensationRates
@import "./variables/workersCompensationRatesVariables.module.scss";

// perfectautowarranty
@import "./variables/perfectautowarrantyVariables.module.scss";

// thecarwarranty
@import "./variables/thecarwarrantyVariables.module.scss";

//antiviruschoice
@import "./variables/antiviruschoiceVariables.module.scss";

//insurancesupersaver
@import "./variables/insurancesupersaverVariables.module.scss";

//websitebuilderselect
@import "./variables/websitebuilderselectVariables.module.scss";

//pointofsalecost
@import "./variables/pointofsalecostVariables.module.scss";

//iforklift
@import "./variables/iForkLiftVariables.module.scss";

//businessphoneprices
@import "./variables/businessphonepricesVariables.module.scss";

//copiercosts
@import "./variables/copiercostsVariables.module.scss";

//medicalsoftwarequotes
@import "./variables/medicalsoftwarequotesVariables.module.scss";

//storagecontainerprices
@import "./variables/storagecontainerpricesVariables.module.scss";

//taxdebtoffers
@import "./variables/taxdebtoffersVariables.module.scss";

//quoto
@import "./variables/quotoVariables.module.scss";

//lawyerconsultation
@import "./variables/lawyerconsultationVariables.module.scss";

//injurycompensation
@import "./variables/injurycompensationVariables.module.scss";

//CreditCardProcessor
@import "./variables/creditcardprocessorVariables.module.scss";

//cardprocessingoffers
@import "./variables/cardprocessingOffersVariables.module.scss";

//payrollsystems
@import "./variables/payrollsystemsVariables.module.scss";

//compareCRMPrices
@import "./variables/compareCRMPricesVariables.module.scss";

//fleettrackingquotes
@import "./variables/fleettrackingquotesVariables.module.scss";
//comparepeorates
@import "./variables/comparepeoratesVariables.module.scss";

//officecubiclequotes
@import "./variables/officecubiclequotesVariables.module.scss";

//wideformatprintercosts
@import "./variables/wideformatprintercostsVariables.module.scss";

//bestzantaclawyers
@import "./variables/bestzantaclawyersVariables.module.scss";

//bestearpluglawyers
@import "./variables/bestearpluglawyersVariables.module.scss";

//bestparaquatlawyers
@import "./variables/bestparaquatlawyersVariables.module.scss";

//contentintelligence
@import "./variables/contentintelligenceVariables.module.scss";

//nextautowarranty
@import "./variables/nextautowarrantyVariables.module.scss";

//talcumclaims
@import "./variables/talcumclaimsVariables.module.scss";

//camplejeuneapplication
@import "./variables/camplejeuneapplicationVariables.module.scss";

//crmOffers
@import "./variables/crmOffersVariables.module.scss";

//atmmachineprices
@import "./variables/atmmachinepricesVariables.module.scss";

//topcommercialcleaners
@import "./variables/topcommercialcleanersVariables.module.scss";

//voipphoneprices
@import "./variables/voipphonepricesVariables.module.scss";

//bestpostagemeters
@import "./variables/bestpostagemetersVariables.module.scss";

//steelbuildingestimates
@import "./variables/steelbuildingestimatesVariables.module.scss";

//businesscopymachines
@import "./variables/businessCopyMachinesVariables.module.scss";

//businesscopymachines
@import "./variables/topcamplejeunelawyersVariables.module.scss";

//disabilityadvocates
@import "./variables/disabilityadvocatesVariables.module.scss";

//bestrounduplawyers
@import "./variables/bestrounduplawyersVariables.module.scss";

//bestbabyformulalawyers
@import "./variables/bestbabyformulalawyersVariables.module.scss";

//bestridesharelawyers
@import "./variables/bestridesharelawyersVariables.module.scss";

//eliteaccidentattorneys
@import "./variables/eliteaccidentattorneysVariables.module.scss";

//accidentinjury
@import "./variables/accidentinjuryVariables.module.scss";

//vehicleinjury
@import "./variables/vehicleinjuryVariables.module.scss";

//pointofsalechoice

@import "./variables/pointofsalechoiceVariables.module.scss";
//carinjuryjustice
@import "./variables/carinjuryjusticeVariables.module.scss";

//cpap
@import "./variables/cpapVariables.module.scss";
//acceptcredit

@import "./variables/acceptcreditVariables.module.scss";

//tepezza

@import "./variables/tepezzaVariables.module.scss";

//autowarrantyprices

@import "./variables/autowarrantypricesVariables.module.scss";
//hairstraightener

@import "./variables/hairstraightenerVariables.module.scss";

//homewarranty

@import "./variables/homewarrantyVariables.module.scss";
//gethomeprotection

@import "./variables/gethomeprotectionVariables.module.scss";

//homewarrantyestimate

@import "./variables/homewarrantyestimateVariables.module.scss";

//debtreliefanalysis

@import "./variables/debtreliefanalysisVariables.module.scss";

//fastdebtconsolidation
@import "./variables/fastdebtconsolidationVariables.module.scss";

//bestvaluehomewarranty
@import "./variables/bestvaluehomewarrantyVariables.module.scss";

//homewarrantycenter
@import "./variables/homewarrantycenterVariables.module.scss";

//payrollsystem
@import "./variables/payrollsystemVariables.module.scss";

//debtreliefhouse
@import "./variables/debtreliefhouseVariables.module.scss";

//debtreliefoffice
@import "./variables/debtreliefofficeVariables.module.scss";

//trustedcarwarranty
@import "./variables/trustedcarwarrantyVariables.module.scss";
