$acceptcredit-main-color: #1b9b9b;
$acceptcredit-whyus-color: #316767;
$acceptcredit-button-color: linear-gradient(180deg, #ff965b 0%, #e77b17 100%);
$acceptcredit-hover-select-color: linear-gradient(
    180deg,
    #faac7e 0%,
    #e77b17 100%
);
:export {
    mainColor: $acceptcredit-main-color;
    whyUs: $acceptcredit-whyus-color;
}
