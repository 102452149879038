
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.phoneNumberMessage {
    font-size: 11px;
    color: #000;
    width: 100%;
    text-align: left;
    padding-left: 45px;
}

.phoneNumber {
    text-align: center;
    a {
        width: fit-content;
        border-width: 2px;
        border-style: solid;
        padding: 8px 20px;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: 15px;
        @include md {
            border: none;
            margin: 0;
            padding: 0;
            border-radius: 0;
        }

        svg > path {
            @include handleAllBreakpoints(
                "fill",
                "iconColor",
                "var(--global-headerAndFooter-header-icon-color, var(--text-color, #000))"
            );
        }

        span {
            font-size: 18px;
            @include handleAllBreakpoints(
                "color",
                "textColor",
                "var(--global-headerAndFooter-header-text-color, var(--text-color, #000))"
            );
        }
    }
}

.sticky {
    position: fixed;
    height: 130px;
    padding-bottom: 10px;
    width: 100vw;
    bottom: 0;
    right: 0;
    z-index: 99;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px -13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    justify-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #000;
    // background-color: #000 !important;
    flex-direction: column;
    @include md {
        background-color: transparent;
        box-shadow: none;
        @apply py-4 px-7;
        // max-width: var(--max-width);
        height: auto;
        display: flex;
        // left: 50%;
        // transform: translateX(-50%);
        width: fit-content;
        height: auto !important;
    }
    @include lg {
        margin-bottom: 60px;
        // left: auto
    }
    @include xxxl {
        @apply px-10;
    }

    a {
        width: fit-content;
        // border-width: 2px;
        // border-style: solid;
        padding: 8px 20px;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: 15px;
        color: var(--button-text-color, #fff);
        transition: width 0.35s ease;

        @include handleAllBreakpoints(
            "color",
            "stickyPhoneNumberTextColor",
            "var(--button-text-color, #fff)"
        );
        @include handleAllBreakpoints(
            "background-color",
            "stickyPhoneNumberBackgroundColor",
            "var(--primary, var(--button-bg-color, #000))::var(--primary, var(--button-bg-color, #bf0a2f))"
        );

        @include md {
            margin: 0;
            border-radius: 999px;
            margin-left: auto;
            font-weight: normal;
            overflow: hidden;
            padding: 4px 15px;
            -webkit-box-shadow: 0 0 0 8px rgba(var(--button-pulse-color), 0.1);
            box-shadow: 0 0 0 8px rgba(var(--button-pulse-color), 0.1);
            -webkit-animation: shadow-pulse 1s infinite;
            -o-animation: shadow-pulse 1s infinite;
            animation: slideIn 0.5s 1s backwards ease, shadow-pulse 1s infinite;
            .number {
                display: none;
                white-space: nowrap;
            }
            width: 155px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                width: 225px;
            }
        }

        &:hover {
            .number {
                display: inline;
            }

            .call-us {
                display: none;
            }
        }
    }

    h3 {
        @include handleAllBreakpoints(
            "color",
            "textColor",
            "var(--global-headerAndFooter-header-text-color, var(--text-color, #000))"
        );
        @include handleAllBreakpoints("color", "stickyTextColor");

        @include md {
            display: none;
        }
    }
}

.slogan {
    @include handleAllBreakpoints(
        "color",
        "textColor",
        "var(--global-headerAndFooter-header-text-color, var(--text-color, #000))"
    );
    @include handleAllBreakpoints("font-size", "textSize", "12px");
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes shadow-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(var(--button-pulse-color), 0.3);
        box-shadow: 0 0 0 0 rgba(var(--button-pulse-color), 0.3);
    }
    50% {
        -webkit-box-shadow: 0 0 0 8px rgba(var(--button-pulse-color), 0.1);
        box-shadow: 0 0 0 8px rgba(var(--button-pulse-color), 0.1);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(var(--button-pulse-color), 0.3);
        box-shadow: 0 0 0 0 rgba(var(--button-pulse-color), 0.3);
    }
}
.phoneIcon {
    animation: jump-shaking 3s infinite;
}
@keyframes jump-shaking {
    0% {
        transform: translateX(0);
    }
    10.5% {
        transform: rotate(17deg);
    }
    16.5% {
        transform: rotate(-17deg);
    }
    19.5% {
        transform: rotate(17deg);
    }
    22.5% {
        transform: rotate(-17deg);
    }
    30%,
    100% {
        transform: translateY(0) rotate(0);
    }
}
