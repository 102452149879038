
            @import 'src/styles/mixins.scss';
        
.linkColor {
    color: #1693f0;
}

.linksList {
    scrollbar-color: #f1f1f1 !important;
    scrollbar-width: thin !important;
    .bulletedListItem:last-child {
        &::before {
            display: none;
        }
    }
}

/* width */
.linksList::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.linksList::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    background-color: #f2f2f2;
    border-radius: 50px;
}

/* Handle */
.linksList::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 50px;
}
.bulletedListItem {
    position: relative;
    &::before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background-color: #eee;
        position: absolute;
        left: 5px;
        top: 8px;
    }
    a {
        &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background-color: #a8b1ba;
            position: absolute;
            top: 5px;
            left: 2px;
            border-radius: 15px;
        }
    }
}
