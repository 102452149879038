
            @import 'src/styles/mixins.scss';
        
.facebook:hover {
    rect {
        fill: #326fb4;
    }
}
.twitter:hover {
    rect {
        fill: #2e8cd4;
    }
}
.pintrest:hover {
    rect {
        fill: #a31026;
    }
}
.facebook {
    rect {
        fill: #5780bb;
    }
}
.twitter {
    rect {
        fill: #5da8f8;
    }
}
